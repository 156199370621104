import React from "react"
import { Link } from "gatsby"
import * as ROUTES from "../constants/routes"
import Layout from "../components/layout"

export default () => (
  <Layout activePage="home">
    <section className="landing">
      <div className="wrapper">
        <div className="row centered">
          <div className="col col-7 home_intro">
            <h2 className="heading--xl bold">
              How internationally ready are you?
            </h2>
            <p className="body--l">
              Areas with lower scores will need more development as part of an
              action plan
            </p>
            <Link to={ROUTES.SIGN_UP} className="btn red">
              <span>sign up to begin</span>
            </Link>

            <p className="body--m signin_link">
              Already have an account? <a href={ROUTES.SIGN_IN}>Sign in</a>
            </p>
          </div>
          <div className="col col-1 col-empty"></div>
          <div className="col col-4">
            <div className="box website_box">
              <span className="icon-website"></span>
              <h2 className="heading--m">Want to know more?</h2>
              <p className="body--s">
                View our service offering to help maximise your overseas
                business opportunities
              </p>
              <a
                href="https://exportcatalyst.com"
                target="_blank"
                className="btn blue"
                rel="noreferrer"
              >
                <span>exportcatalyst.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
